<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="Rekap Absensi"
          subtitle="Halaman untuk rekap absensi siswa"
          :items="attendances"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :search="search"
          :classes="classes"
          :is-loading="isLoadingData"
          :print-recap-button="printRecapButton"
          :filter-student-attendance="filterStudentAttendance"
          :is-loading-button="isLoadingButton"
          :is-disabled-button="!filterValid"
          :no-add-button="noAddButton"
          @page="page"
          @live-search="searchHandler"
          @close-from-date-atd="handleCloseFromDate($event)"
          @close-to-date-atd="handleCloseToDate($event)"
          @print-recap-class="showFormRecapclass"
          @print-recap-student="showFormRecapStudent($event)"
          @change-page="paginationHandler"
          @show-result="showResultFilter"
          @filter-classes="handleFilterClass($event)"
          @filter-from-date-atd="handleFilterFromDate($event)"
          @filter-to-date-atd="handleFilterToDate($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :dialog="dialog"
      text-button="Cetak"
      :header="dialog === 'class'? 'Cetak Absensi Kelas' : 'Cetak Absensi Siswa'"
      :is-disabled-button="!formValid"
      :is-loading-button="isLoadingButton"
      @handler-button="handlerButton"
      @close-modal="handleClose"
      @close-button="handleClose"
    >
      <template v-slot:body>
        <div v-if="dialog == 'class'">
          <v-autocomplete
            v-model="recap.class_uuid"
            label="Pilih Kelas"
            outlined
            :items="classes"
            item-text="name"
            item-value="uuid"
            dense
            clearable
            @change="handleClass($event)"
          ></v-autocomplete>
        </div>
        <div v-if="dialog == 'class'">
          <v-menu
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="80"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="recap.date"
                label="Bulan"
                :prepend-icon="icons.mdiCalendarMonthOutline"
                readonly
                outlined
                dense
                v-bind="attrs"
                clearable
                @click="autofillMonth"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="recap.date"
              type="month"
              locale="id-id"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </div>
        <div v-if="dialog == 'student'">
          <div class="d-flex mb-4">
            <div class="me-4">
              <p>Nama Siswa </p>
              <p>Kelas </p>
            </div>
            <div>
              <p>: {{ student.name }}</p>
              <p>: {{ student.class[0].name }}</p>
            </div>
          </div>
          <div>
            <v-autocomplete
              v-model="recap.year"
              label="Pilih Tahun"
              outlined
              :items="years"
              item-text="title"
              item-value="value"
              dense
              clearable
              @change="handleYear($event)"
            ></v-autocomplete>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiCalendarMonthOutline, mdiMagnify } from '@mdi/js'

export default {
  name: 'AttendanceRecap',
  components: {
    SkeletonLoaderTable,
    ModalDialog,
    DataTablePagination,
    MainCard,
  },
  data() {
    return {
      page: 1,
      totalPages: 0,
      totalPageData: this.totalPages,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      modalDialog: false,
      noAddButton: true,
      printRecapButton: true,
      filterStudentAttendance: true,
      formValid: false,
      filterValid: false,
      totalItems: 0,
      search: '',
      filterQuery: {
        search: '',
        class: '',
        start_date: '',
        end_date: '',
      },
      icons: {
        mdiMagnify,
        mdiCalendarMonthOutline,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Lengkap', value: 'full_name' },
        { text: 'Kelas', value: 'class[0].name' },
        { text: 'Hadir (H)', value: 'hadir' },
        { text: 'Alfa (A)', value: 'alpha' },
        { text: 'Sakit (S)', value: 'sakit' },
        { text: 'Izin (S)', value: 'izin' },
        { text: 'Actions', value: 'print-qr' },
      ],
      start_date: null,
      end_date: null,
      date: false,
      recap: {
        start_date: '',
        end_date: '',
        date: '',
        year: '',
        class_uuid: '',
      },
      service: 'attendance',
      attendances: [],
      students: [],
      classes: [{ uuid: 0, name: 'Semua' }],
      class_uuid: '',
      class: '',
      class_name: '',
      school: [],
      years: [
        { index: 1, title: '2020', value: '2020' },
        { index: 2, title: '2021', value: '2021' },
        { index: 3, title: '2022', value: '2022' },
        { index: 4, title: '2023', value: '2023' },
        { index: 5, title: '2024', value: '2024' },
        { index: 6, title: '2025', value: '2025' },
        { index: 7, title: '2026', value: '2026' },
        { index: 8, title: '2027', value: '2027' },
        { index: 9, title: '2028', value: '2028' },
        { index: 10, title: '2029', value: '2029' },
        { index: 11, title: '2030', value: '2030' },
      ],
      school_uuid: '',
      student_uuid: '',
      student: {},
      dialog: '',
      from_date_atd: '',
      to_date_atd: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.listAttendance()
      },
    },
    recap: {
      handler() {
        const valid = []
        let requiredField = []
        if (this.dialog === 'class') {
          requiredField = ['class_uuid', 'date']
        } else {
          requiredField = ['year']
        }
        Object.entries(this.recap).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    filterQuery: {
      handler() {
        const valid = []
        const requiredField = ['class', 'start_date', 'end_date']
        Object.entries(this.filterQuery).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.filterValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    await this.listAttendance()
    await this.listClass()
  },
  methods: {
    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listAttendance(this.filterQuery)
    },
    handleCloseFromDate(event) {
      if (event === null) {
        this.filterQuery.start_date = ''
        this.listAttendance()
      }
    },
    handleCloseToDate(event) {
      if (event === null) {
        this.filterQuery.end_date = ''
        this.listAttendance()
      }
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    resetForm() {
      this.recap.class_uuid = ''
      this.recap.date = ''
      this.recap.year = ''
      this.filterQuery.start_date = ''
      this.filterQuery.end_date = ''
    },
    async listAttendance(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        search: this.search,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(({ data }) => {
        this.attendances = data.data.map((attendance, index) => ({
          ...attendance,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async listClass() {
      await this.$services.ApiServices.list('class', { school_uuid: this.school_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async getStudent(uuid) {
      await this.$services.ApiServices.get('student', uuid).then(({ data }) => {
        this.student = data.data
      })
    },
    async showFormRecapclass() {
      this.dialog = 'class'
      this.modalDialog = true
      this.resetForm()
    },
    async showFormRecapStudent(uuid) {
      await this.getStudent(uuid)
      this.student_uuid = uuid
      this.dialog = 'student'
      this.modalDialog = true
      this.resetForm()
    },
    async printRecapClass(params = {}) {
      this.isLoadingButton = true
      await this.$services.PrintServices.printRecap('recapclass', {
        ...params,
        school_uuid: this.school_uuid,
        month: this.recap.date.slice(-1),
        class: this.class,
      }).then(
        data => {
          this.showSnackbar({
            text: 'Berhasil Cetak Rekapan Absensi Kelas',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `recap-${this.class_name}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.modalDialog = false
          console.error(err)
        },
      )
    },
    async printRecapStudent(params = {}) {
      this.isLoadingButton = true
      await this.$services.PrintServices.printRecap('recapstudent', this.student_uuid, {
        ...params,
        year: this.recap.year,
      }).then(
        data => {
          this.showSnackbar({
            text: 'Berhasil Cetak Rekapan Absensi Siswa',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `recap-${this.student.name}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.modalDialog = false
          console.error(err)
        },
      )
    },
    handlerButton() {
      if (this.dialog === 'class') this.printRecapClass()
      else this.printRecapStudent()
    },
    async handleClass(uuid) {
      this.class_uuid = uuid
      await this.$services.ApiServices.get('class', uuid).then(
        async ({ data }) => {
          this.class_name = data.data.name
        },
        err => console.error(err),
      )
    },
    handleYear(year) {
      this.recap.year = year
    },
    handleClose() {
      this.isLoadingButton = false
      this.modalDialog = false
    },
    handleFilterClass(uuid) {
      this.filterQuery.class = uuid
      if (uuid === null) {
        this.filterQuery.class = ''
        this.resetForm()
        this.listAttendance()
      }
    },
    handleFilterFromDate(date) {
      this.filterQuery.start_date = date
    },
    handleFilterToDate(date) {
      this.filterQuery.end_date = date
    },
    showResultFilter() {
      this.isLoadingButton = true
      this.listAttendance({
        class_uuid: this.filterQuery.class,
        start_date: this.filterQuery.start_date,
        end_date: this.filterQuery.end_date,
      })
      this.isLoadingButton = false
    },
    autofillMonth() {
      this.recap.date = new Date().toISOString().substr(0, 7)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
