<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="QR CODE"
          subtitle="Halaman untuk cetak QR Code Siswa"
          :items="students"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :headers="headers"
          :search="search"
          :is-loading="isLoadingData"
          :is-loading-button="isLoadingButton"
          :is-loading-button-action="user_uuid"
          :is-disabled-button="!class_uuid"
          :no-add-button="noAddButton"
          :filter-class="filterClass"
          :print-qr-button="printQrButton"
          :items-class="classes"
          @page="page"
          @live-search="searchHandler"
          @change-page="paginationHandler"
          @filter-classes="filterByClass($event)"
          @generate-qr-user="generateQrCodeUser($event)"
          @generate-qr-class="generateQrCodeClass($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiMagnify } from '@mdi/js'

export default {
  components: {
    DataTablePagination,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      filterQuery: {
        search: '',
      },
      search: '',
      icons: {
        mdiMagnify,
      },
      isLoadingData: false,
      isLoadingTable: true,
      isLoadingButton: false,
      noAddButton: true,
      printQrButton: true,
      filterClass: true,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nis', value: 'nis' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Kelas', value: 'class[0].name' },
        { text: 'Aksi', value: 'print-qr-user' },
      ],
      school_uuid: '',
      class: '',
      class_uuid: '',
      school: [],
      students: [],
      classes: [],
      user_uuid: '',
    }
  },
  watch: {
    class_uuid: {
      handler() {
        this.listStudent()
      },
    },
    page: {
      handler() {
        this.listStudent(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listClass()
    this.listStudent()
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.uuid
      })

      return true
    },
    async listClass(params = {}) {
      await this.$services.ApiServices.list('class', {
        ...params,
        school_uuid: this.school_uuid,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },
    async listStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('student', {
        ...params,
        school_uuid: this.school_uuid,
        class: this.class_uuid,
      }).then(
        ({ data }) => {
          this.students = data.data.map((student, index) => ({
            ...student,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async generateQrCodeUser(uuid) {
      this.user_uuid = uuid
      this.formData = new FormData()
      this.formData.append('user_uuid', this.user_uuid)
      await this.$services.ApiServices.add('qrcodeuser', this.formData).then(
        async () => {
          this.showSnackbar({
            text: 'Qr code user sedang di generate',
            color: 'success',
          })
          await this.printQrCodeUser()
        },
        err => {
          this.printQrCodeUser()
          console.error(err)
        },
      )
    },
    async printQrCodeUser() {
      await this.$services.PrintServices.print('printqruser', this.user_uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Berhasil cetak qr code siswa',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `qr-student-${this.user_uuid}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.user_uuid = ''
        },
        err => {
          this.isLoadingButton = false
          this.user_uuid = ''
          console.error(err)
        },
      )
    },
    async generateQrCodeClass() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('class_uuid', this.class_uuid)
      await this.$services.ApiServices.add('qrcodeclass', this.formData).then(
        async () => {
          this.showSnackbar({
            text: 'Qr code kelas sedang di generate',
            color: 'success',
          })
          await this.printQrCodeClass()
        },
        err => {
          console.error(err)
        },
      )
    },
    async printQrCodeClass() {
      await this.$services.PrintServices.print('printqrclass', this.class_uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Berhasil cetak qr code kelas',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `qr-class-${this.class_uuid}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    filterByClass(uuid) {
      this.class_uuid = uuid
      if (uuid === null) {
        this.class_uuid = ''
      }
    },
    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listStudent(this.filterQuery)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
